import React from 'react';

const FormSubmitThanks = () => {
    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.title}>Thank You!</h1>
                <p style={styles.message}>We have received your submission and will get back to you shortly.</p>
                <a href="/" style={styles.button}>Go Back to Home</a>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f4f4', // Adjust to match your theme background
    },
    card: {
        padding: '40px',
        textAlign: 'center',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: '2rem',
        color: '#333', // Adjust to match your theme color
    },
    message: {
        fontSize: '1.2rem',
        color: '#666', // Adjust as per the color scheme
        margin: '20px 0',
    },
    button: {
        textDecoration: 'none',
        padding: '10px 20px',
        color: '#fff',
        backgroundColor: '#007bff', // Adjust to match your theme's primary color
        borderRadius: '5px',
    },
};

export default FormSubmitThanks;
